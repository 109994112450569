<template>
    <div class="conversation-list-box">
      
        <div class="conversation-list-item" 
            v-for="(item,index) in msgArry" 
            :key="index" 
            :class="{'active':item.targetId == selectId}"
            @click="onChangeUser(item)"
            >
            <!-- {{ item }} -->
            <img :src="item.avatar" alt="" class="conversation-list-head">
            <div class="conversation-list-msg">
                <div class="conversation-list-msg-title">{{item.remark || item.nickName}}</div>
                <!-- <div class="conversation-list-msg-text">{{ item.msg }}</div> -->
                <div class="conversation-list-msg-tag" v-if="item.unreadMessageCount">{{ item.unreadMessageCount }}</div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  
  // const RongIMLib = require('@rongcloud/imlib-next')
  export default {
    name: 'ConversationList',
    props: {
      msg: String,
      msgArry: Array,
      selectId: String
    },

    methods: {
      onChangeUser(item){
        console.log(item.targetId == this.selectId);
        if(item.targetId == this.selectId) return
        this.$emit("changeItem", item);
      } 
      // onChangeUser
      // getList(){

      // }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .conversation-list-box{
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid #c3c3c3;
    background-color: #fff;
  }

  .conversation-list-item{
    display: flex;
    padding: 16px;
    background-color: #e5e4e4;
    border-bottom: 1px solid #eee;
  }
  .active{
    background-color: #ff953e;
  }
  .conversation-list-head{
    width: 50px;
    height: 50px;
  } 
  .conversation-list-msg{
    flex: 1;
    text-align: left;
    margin-left: 16px;
  }
  .conversation-list-msg-title{
    font-weight: bold;
    margin-bottom: 6px;
  }
  .conversation-list-msg-tag{
    background-color: #f00;
    width: auto;
    height: 20px;
    border-radius: 20px;
    padding: 0 6px;
    display: inline-block;
    color: #fff;
  }
  </style>
  