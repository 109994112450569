<template>
  <page-header-wrapper>
    <a-card :bordered="false">
     
      <!-- 13123213
      <a-button type="primary" @click="loginKf(record, undefined)">
        <a-icon type="plus1" />{{$t('通用.按钮.平台客服')}}
      </a-button> -->
      <div class="talk-box">
        <a-layout class="talk-box-item">
          <a-layout-sider>
            <conversation-list :msgArry="msgArry" :selectId="selectId" @changeItem="onChangeUser"></conversation-list>

          </a-layout-sider>
          <a-layout>
            <!-- <a-layout-header>Header</a-layout-header> -->
            <a-layout-content>
            
              <!-- <message-history ref="msghistory" :selectId="selectId" :name="selectId" :user="user"></message-history> -->
              <message-list ref="msglist" :selectId="selectId" :name="selectId" :selectUser="selectUser" :user="rongyunId"></message-list>
            </a-layout-content>
            <a-layout-footer>
              <message-deitor :msgArry="msgArry"  @sendMsg="sendMssage"></message-deitor>
            </a-layout-footer>
          </a-layout>
        </a-layout>
      </div>
      
    </a-card>
    <a-modal
          class="yinshipin-dialog"
          v-model="dialogYinShiPin"
          
          :footer="null"
          :closable="false"
          :fullscreen="false"
          :maskClosable="false"
          :show-close="false"
          :destroy-on-close="false"
          @closed="dialogYinShiPin = false"
          style="text-align: center; ">
           <!-- <player :video="video" v-if="playerVisible"></player> -->
        <!-- <video ref="videoPlayer" v-if="videoUrl" class="video-item" controls @click="onplay">
          <source :src="videoUrl" type="video/mp4">
        </video> -->
        <div class="yinshipin-container">
          
          <div class="yinshipin-video-box">
              <div id="videoView" class="yinshipin-video-box-item">

              </div>
          </div>
          <div class="yinshipin-box">
              <div class="yinshipin-head">{{yinshipinType|filterCall}}</div>
              <div class="yinshipin-content">
                <img src="./modules/logo.png" alt="" class="yinshipin-content-head">
                <div class="yinshipin-content-name">{{ selectId }}</div>
              </div>
              <div class="yinshipin-bottom">
                <div class="yinshipin-bottom-item" >
                  <img src="./modules/callmac.png" alt="" class="yinshipin-bottom-img">
                  <div>
                    麦克风
                  </div>
                </div>
                <div class="yinshipin-bottom-item" v-if="yinshipinType == 11 || yinshipinType == 12" @click="hungup">
                  <img src="./modules/callred.png" alt="" class="yinshipin-bottom-img">
                  <div>
                    取消
                  </div>
                </div>
                <div class="yinshipin-bottom-item" @click="onplayVide" >
                  <img src="./modules/calllb.png" alt="" class="yinshipin-bottom-img">
                  <div>
                    扬声器
                  </div>
                </div>
                
                <div class="yinshipin-bottom-item" @click="hungup" v-if="yinshipinType == 21 || yinshipinType == 22">
                  <img src="./modules/callred.png" alt="" class="yinshipin-bottom-img">
                  <div>
                    挂断
                  </div>
                </div>
                <div class="yinshipin-bottom-item" @click="accept" v-if="yinshipinType == 21 ">
                  <img src="./modules/callgreen.png" alt="" class="yinshipin-bottom-img">
                  <div>
                    接听
                  </div>
                </div>
              </div>
          </div>
        </div>
          


        </a-modal>
  </page-header-wrapper>
</template>

<script>
import {pageShop, listShop, delShop, updateShopRecommend,ptkf,queryAutographSign,queryKfUserList,
queryKfNoticePage,
queryKfNoticeCount,
addKfNoticeCount} from '@/api/shop/shop'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import moment from 'moment';
import CreateForm from './modules/CreateForm'
import {tableMixin} from '@/store/table-mixin'
import {listShopCategory} from "@/api/shop/shopCategory";
import bus from "@/utils/bus";
import { init } from 'echarts';

// IM 代码
import ConversationList from './modules/conversation-list.vue'
import MessageList from './modules/message-list.vue'
import MessageHistory from './modules/message-history.vue'
import MessageDeitor from './modules/message-deitor.vue'
const RongIMLib = require('@rongcloud/imlib-next')
import { installer as rtcInstaller, RCRTCClient, RCTrack, RCFrameRate, RCResolution } from "@rongcloud/plugin-rtc";
import { installer as callInstaller, RCCallClient, RCCallSession, RCCallErrorCode, ISessionListener, IEndSummary, ISenderInfo, IMuteUser, IInvitedUsers, RCCallLanguage, RCCallEndReason, RCCallMediaType, IOfflineRecord, RCCallSessionState } from "@rongcloud/plugin-call";
 


export default {
  name: 'Shop',
  components: {
    CustomDictTag,
    ConversationList,
    MessageList,
    MessageDeitor,
    MessageHistory
    // CreateForm
  },
  mixins: [tableMixin],
  data() {
    return {
      
      list: [],
      rongyunId:'',
      rongyunToken:'',
      msgArry:[],
      selectId:'',
      selectUser:'',
      talkLeftUser:'客户100001', //聊天对象
      talkRightUser:'', //聊天人自己的信息
      user:'',
      rtcClient:'',
      caller:'',

      sessionObj:'',
      dialogYinShiPin:false,
      callType: false, //音视频占用状态
      yinshipinType: 0, //音视频状态  0:没有使用   11：主叫拨号  12：主叫已接通  13：主叫挂断   21：被叫振铃中  22：被叫已接通  23:被叫已挂断
      
    }
  },
  filters: {
    filterCall(val){
      let text="未使用";
      switch (val) {
        case 11:
          text = "拨号中..."
        break;
        case 12:
          text = "通话中"
        break;
        case 13:
          text = "已挂断"
        break;
        case 21:
          text = "振铃中..."
        break;
        case 22:
          text = "通话中"
        break;
        case 23:
          text = "已挂断"
        break;
        default:
          // 默认代码块
          break;
      }
      return  text
    }
  },
  created() {
    this.messageLook()
    this.loginKf('init')
   
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  methods: {
    moment,
   
    /** 获取客服imtoken **/
    loginKf(type) {
      let that = this;
      ptkf().then(response => {
        console.log('客服',response)
        this.rongyunToken = response.data.tenCenImSig;
        this.rongyunId = response.data.id
        if(type == 'init'){
          this.loginIm()
        }
        // let openUrl ='http://kf.qyg1001.com/dist?id='+response.data.id+''+'&usersig='+response.data.tenCenImSig;
        // window.open(openUrl, 'kfWidow',"444");
      })
      
    },


    async loginIm(){
      console.log('初始化----')
      let that = this;
      let token =  this.rongyunToken;
      const res =  await RongIMLib.connect(token);
      console.log('初始化----',res)
        // 4.获取会话列表
        RongIMLib.getConversationList().then((e) => {
          if (e.code === 0) {
            console.log('获取会话列表成功', e);

            let dataArry = e.data.filter((item)=>{
                item.targetId.includes('kf')
            })

            let userId = e.data.map(e=>{
							
							return e.targetId
						})
            console.log('过滤会话列表成功1', userId);
            let userIds = userId.filter(e=>{
               return e.indexOf('kf') == -1; 
            })
            console.log('过滤会话列表成功1', userIds);
            
            queryKfUserList({userIds:userIds}).then(response => {
              console.log('用户列表',response)
              console.log('会话列表',e)
              let array = response.data.map(item=>{
                let mergedObj = '' 
                for(let i = 0 ; i < e.data.length; i++ ){
											// console.log('获取客服列表4',res2.data.data[i].targetId, e.targetId)
											if(item.id == e.data[i].targetId) {
												
												mergedObj = Object.assign({}, e.data[i],item);
												
												 // console.log('获取客服列表5',mergedObj)
												 //return mergedObj
											}
                    
										}
                    return mergedObj
            
              })

              // this.rongyunToken = response.data.tenCenImSig;
              
              // console.log('过滤会话列表成功2', userIds);
              this.msgArry = array
              // 获取会话用户信息

              console.log('用户列表111111111',array)
              this.selectId =  this.msgArry[0].targetId
              this.selectUser =  this.msgArry[0]
              this.clearReadMessage()
              // let openUrl ='http://kf.qyg1001.com/dist?id='+response.data.id+''+'&usersig='+response.data.tenCenImSig;
              // window.open(openUrl, 'kfWidow',"444");
            })
      


          
          } else {
            console.log('获取会话列表失败: ', e);
          }
        });


         // RTC 客户端初始化
        // const rtcClient = RongIMLib.installPlugin(rtcInstaller, { /* 配置项 */ })
        // console.log('初始化22222222',rtcClient)
        // that.caller = RongIMLib.installPlugin(callInstaller,{
        // // rtcClient 实例 （必填）
        // rtcClient,
        // /**
        //  * 被动收到邀请 （收到一个远端发起的新会话）, 会产生一个新的 session 对象 （必填）
        //  */
        // onSession: (session) => {
        //   console.log(`收到的通话邀请`);
          
          
        //     /**
        //      * **收到新的 session 后需要立即注册事件监听**
        //      */
        //     that.registerCallSessionEvent(session);

        //     that.callGet(session);
        // },
        // onSessionClose: (session, summary) => {
        //     console.log('通话已结束1',summary,session)
        //     that.dialogYinShiPin = false;
        //     that.yinshipinType = 0;
        //       // RCToast('通话已结束');
        //       // RCCallView.end();
        //       // removeVideoEl();
        // },
        //  /**
        //  * 接收 IM 离线期间收到的呼叫记录（按需监听）
        //  */
        // onOfflineRecord(record){
        //   console.log('通话已结束2',summary,session)
        // },
        // });
        // console.log('初始化完成')
      },
      readMessage(message){
        console.log('消息已读状态',message)
        // let sendId  = this.isUserType == 2? this.sendId : this.sendId+'kf';
        RongIMLib.sendReadReceiptMessage(this.selectId, message.messageUId, message.sentTime).then(res => {
          if (res.code === 0) {
            console.log('消息已读',res.code, res.data)
          } else {
            console.log('消息已读',res.code, res.msg)
          }
          RongIMLib.getConversationList().then((e) => {
            if (e.code === 0) {
              let dataArry = e.data.filter((item)=>{
                item.targetId.includes('kf')
            })

            let userId = e.data.map(e=>{
							
							return e.targetId
						})
            console.log('过滤会话列表成功1', userId);
            let userIds = userId.filter(e=>{
               return e.indexOf('kf') == -1; 
            })
            console.log('过滤会话列表成功1', userIds);
              console.log('获取会话列表成功', e);
              queryKfUserList({userIds:userIds}).then(response => {
                        console.log('用户列表',response)
                        console.log('会话列表',e)
                        let array = response.data.map(item=>{
                          let mergedObj = '' 
                          for(let i = 0 ; i < e.data.length; i++ ){
                                // console.log('获取客服列表4',res2.data.data[i].targetId, e.targetId)
                                if(item.id == e.data[i].targetId) {
                                  
                                  mergedObj = Object.assign({}, e.data[i],item);
                                  
                                  // console.log('获取客服列表5',mergedObj)
                                  //return mergedObj
                                }
                              
                              }
                              return mergedObj
                      
                        })

                        // this.rongyunToken = response.data.tenCenImSig;
                        
                        // console.log('过滤会话列表成功2', userIds);
                        this.msgArry = array
                        // 获取会话用户信息
                        // let openUrl ='http://kf.qyg1001.com/dist?id='+response.data.id+''+'&usersig='+response.data.tenCenImSig;
                        // window.open(openUrl, 'kfWidow',"444");
                      })
          
            } else {
              console.log('获取会话列表失败: ', e);
            }
          });
        }).catch(error => {
          console.log(error)
        })
      },
      clearReadMessage(){
				
				RongIMLib.clearMessagesUnreadStatus({ conversationType:RongIMLib.ConversationType.PRIVATE, targetId:this.selectId }).then(res => {
				  if (res.code === 0) {
					console.log('清除消息已读状态')
				    console.log(res.code)
				  } else {
				    console.log(res.code, res.msg)
				  }
          RongIMLib.getConversationList().then((e) => {
            if (e.code === 0) {
              let dataArry = e.data.filter((item)=>{
                item.targetId.includes('kf')
            })

            let userId = e.data.map(e=>{
							
							return e.targetId
						})
            console.log('过滤会话列表成功1', userId);
            let userIds = userId.filter(e=>{
               return e.indexOf('kf') == -1; 
            })
            console.log('过滤会话列表成功1', userIds);
              console.log('获取会话列表成功', e);
              queryKfUserList({userIds:userIds}).then(response => {
                        console.log('用户列表',response)
                        console.log('会话列表',e)
                        let array = response.data.map(item=>{
                          let mergedObj = '' 
                          for(let i = 0 ; i < e.data.length; i++ ){
                                // console.log('获取客服列表4',res2.data.data[i].targetId, e.targetId)
                                if(item.id == e.data[i].targetId) {
                                  
                                  mergedObj = Object.assign({}, e.data[i],item);
                                  
                                  // console.log('获取客服列表5',mergedObj)
                                  //return mergedObj
                                }
                              
                              }
                              return mergedObj
                      
                        })

                        // this.rongyunToken = response.data.tenCenImSig;
                        
                        // console.log('过滤会话列表成功2', userIds);
                        this.msgArry = array
                        // 获取会话用户信息

                        // let openUrl ='http://kf.qyg1001.com/dist?id='+response.data.id+''+'&usersig='+response.data.tenCenImSig;
                        // window.open(openUrl, 'kfWidow',"444");
                      })
          
            } else {
              console.log('获取会话列表失败: ', e);
            }
          });
				})
			},
      messageLook(){
          console.log('监听')
          let that = this;
          // 2 设置监听   3 登录IM  4.获取会话列表
            const Events = RongIMLib.Events
            RongIMLib.addEventListener(Events.CONNECTING, () => {
                console.log('正在链接服务器')
            })
            RongIMLib.addEventListener(Events.CONNECTED, () => {
                console.log('已经链接到服务器')
            })
            RongIMLib.addEventListener(Events.MESSAGES, (evt) => {
                console.log('消息监听',evt.messages)
                if(evt.messages[0].targetId == this.selectId){
                  that.$refs.msglist.upDataList(evt.messages[0]) 
                  that.readMessage(evt.messages[0])
                }
                RongIMLib.getConversationList().then((e) => {
                    if (e.code === 0) {
                      let dataArry = e.data.filter((item)=>{
                        item.targetId.includes('kf')
                    })

                    let userId = e.data.map(e=>{
                      
                      return e.targetId
                    })
                    console.log('过滤会话列表成功1', userId);
                    let userIds = userId.filter(e=>{
                      return e.indexOf('kf') == -1; 
                    })
                    console.log('过滤会话列表成功1', userIds);
                      console.log('获取会话列表成功', e);
                      queryKfUserList({userIds:userIds}).then(response => {
                        console.log('用户列表',response)
                        console.log('会话列表',e)
                        let array = response.data.map(item=>{
                          let mergedObj = '' 
                          for(let i = 0 ; i < e.data.length; i++ ){
                                // console.log('获取客服列表4',res2.data.data[i].targetId, e.targetId)
                                if(item.id == e.data[i].targetId) {
                                  
                                  mergedObj = Object.assign({}, e.data[i],item);
                                  
                                  // console.log('获取客服列表5',mergedObj)
                                  //return mergedObj
                                }
                              
                              }
                              return mergedObj
                      
                        })

                        // this.rongyunToken = response.data.tenCenImSig;
                        
                        // console.log('过滤会话列表成功2', userIds);
                        this.msgArry = array
                    
                        // 获取会话用户信息
                        // let openUrl ='http://kf.qyg1001.com/dist?id='+response.data.id+''+'&usersig='+response.data.tenCenImSig;
                        // window.open(openUrl, 'kfWidow',"444");
                      })
                      // this.msgArry = e.data
                      // this.selectId = e.data[0].targetId
                    } else {
                      console.log('获取会话列表失败: ', e);
                    }
              });
            })



        },
        // queryKfNoticePage,
        // queryKfNoticeCount
        onplayVide(){
          const container = document.getElementById("videoView");
            const uid = '12312312';
            const node = document.createElement('div');
            node.setAttribute('id', `video-${uid}`);
            const videoTpl = `<span class="video-user-id">ID: ${uid}</span>
              <span class="video-media-type">视频</span>
              <video id="${uid}"></video>`;
            node.innerHTML = videoTpl;
            node.classList = 'video-item';
            // container.appendChild(node);
            // const videoEl = document.getElementById(track.getUserId());

            
          // console.log(videoEl)
        },

        // 发送消息
        async sendMssage(msg){
        console.log('发送消息内容',msg)
        let that = this;
        if(msg.type == 'text'){
          const conversation = {
            // targetId
            targetId: this.selectId,
            // 会话类型：RongIMLib.ConversationType.PRIVATE | RongIMLib.ConversationType.GROUP
            conversationType: RongIMLib.ConversationType.PRIVATE 
          };

          // 构建文本消息
          const message = new RongIMLib.TextMessage({ content: msg.message })
          console.log('发送消息内容',message)
          // 发送消息
          RongIMLib.sendMessage(conversation, message).then(({ code, data }) => {
            if (code === 0) {
              console.log('消息发送成功：', data)

              that.$refs.msglist.upDataList(data) 
              that.addKfNotice(data)
            } else {
              console.log('消息发送失败：', code)
            }
          });
          
          this.clearReadMessage()
        }else if(msg.type == 'image'){
          const conversation = {
            // targetId
            targetId: this.selectId,
            // 会话类型：RongIMLib.ConversationType.PRIVATE | RongIMLib.ConversationType.GROUP
            conversationType: RongIMLib.ConversationType.PRIVATE 
          };

          // 构建图片消息
          const message = new RongIMLib.ImageMessage({ content: msg.content, imageUri: msg.imageUri})
          console.log('发送消息内容',message)
          // 发送消息
          RongIMLib.sendMessage(conversation, message).then(({ code, data }) => {
            if (code === 0) {
              console.log('消息发送成功：', data)

              that.$refs.msglist.upDataList(data) 
              that.addKfNotice(data)
            } else {
              console.log('消息发送失败：', code)
            }
          });
          
          this.clearReadMessage()
        }else if(msg.type == 'video'){
          const conversation = {
            // targetId
            targetId: this.selectId,
            // 会话类型：RongIMLib.ConversationType.PRIVATE | RongIMLib.ConversationType.GROUP
            conversationType: RongIMLib.ConversationType.PRIVATE 
          };

          // 构建图片消息
          const message = new RongIMLib.SightMessage({  
            sightUrl: msg.content.sightUrl,
            content: msg.content.content,
            duration: msg.content.duration,
            size: msg.content.size,
            name: "视频消息"
          })
          
          // 发送消息
          RongIMLib.sendMessage(conversation, message).then(({ code, data }) => {
            if (code === 0) {
              console.log('消息发送成功：', data)

              that.$refs.msglist.upDataList(data) 
              that.addKfNotice(data)
            } else {
              console.log('消息发送失败：', code)
            }
          });
          
          this.clearReadMessage()
        }else if(msg.type == 'yuyin'){
            console.log('发送语音',that)
            // console.log(that.caller)
            const { code, session } = await that.caller.call({
              targetId: that.selectId, //用户id
              mediaType: RCCallMediaType.AUDIO, // 音频呼叫
              listener: {

                /**
                   * 当远端用户已开始响铃，表示对方已收到呼叫请求 （必填）
                   * @param sender 已响铃的用户
                   * @param session 当前的 session 对象
                   */
                  onRinging: (sender, session) => {
                      const { userId } = sender;
                      console.log('当远端用户已开始响铃，表示对方已收到呼叫请求',sender,session)
                      that.yinshipinType = 11;
                      that.callType = true;
                      that.dialogYinShiPin = true;
                      that.sessionObj = session;
                  },

                  /**
                   * 当远端用户同意接听 （必填）
                   * @param sender 远端用户
                   * @param session 当前的 session 对象
                   */
                  onAccept: (sender, session) => {
                      const { userId } = sender;
                      console.log('当远端用户同意接听',sender,session)
                      that.yinshipinType = 12;
                  },

                  /**
                   * 当有远端用户挂断 （必填）
                   * @param sender 远端用户
                   * @param reason 挂断的原因
                   * @param session 当前的 session 对象
                   */
                  onHungup: (sender, reason, session) => {
                      const { userId } = sender;
                      console.log('当有远端用户挂断',sender,reason,session)
                      that.yinshipinType = 0;
                      that.callType = false;
                      that.dialogYinShiPin = false; 
                      
                      document.getElementById("videoView").innerHTML = '';

                  },

                  /**
                   * 本端资源或远端资源已获取， （必填）
                   * @param track 本端资源或远端资源, track不可设置成 Vue 组件的响应式数据
                   * @param session 当前的 session 对象
                   */
                  onTrackReady: (track, session) => {

                      // track.isLocalTrack() 是否为本地资源
                      // track.isAudioTrack() 是否为音频
                      // track.isVideoTrack() 是否为视频
                      // track.getUserId()    产生该 track 的用户id

                      console.log('本端资源或远端资源已获取',track,session)
                        // 播放音频。如果为远端音频，建议直接播放。如为本端音频，建议不播放，以减少回音。
                      if (track.isAudioTrack() && !track.isLocalTrack()) {
                          track.play();
                      }

                  },
                   /**
                       * 通话类型改变时触发，例如： 从视频变成音频通话 （必填）
                       * @param sender 发送者
                       * @param mediaType 1为音频通话   2为视频通话
                       * @param session 当前的 session 对象
                       */
                      onMediaModify: (sender, mediaType, session) => {
                        
                        console.log('当有远端用户挂断',sender,mediaType,session)
                      },
                      /**
                       * 对方静音后触发 （必填）
                       * @param muteUser 已静音的用户
                       * @param session  当前的 session 对象
                       */
                      onAudioMuteChange: (muteUser, session) => {
                        
                        console.log('对方静音后触发',muteUser,session)
                      },

                      /**
                       * 对方禁用视频后触发 （必填）
                       * @param muteUser 已禁用视频的用户
                       * @param session  当前的 session 对象
                       */
                      onVideoMuteChange: (muteUser, session) => {
                        
                        console.log('对方禁用视频后触发',muteUser,session)
                      },
                 
               
                 

                 
              }
            })

          if (code === RCCallErrorCode.SUCCESS) {
              // do something

              console.log('通话成功')
          }
        }else if(msg.type == 'shipin'){
            console.log('发送视频',that)
            // console.log(that.caller)
            const { code, session } = await that.caller.call({
              targetId: that.selectId, //用户id
              mediaType: RCCallMediaType.AUDIO_VIDEO, // 视频呼叫
              listener: {

                /**
                   * 当远端用户已开始响铃，表示对方已收到呼叫请求 （必填）
                   * @param sender 已响铃的用户
                   * @param session 当前的 session 对象
                   */
                  onRinging: (sender, session) => {
                      const { userId } = sender;
                      console.log('当远端用户已开始响铃，表示对方已收到呼叫请求',sender,session)
                      that.yinshipinType = 11;
                      that.callType = true;
                      that.dialogYinShiPin = true;
                      that.sessionObj = session;
                  },

                  /**
                   * 当远端用户同意接听 （必填）
                   * @param sender 远端用户
                   * @param session 当前的 session 对象
                   */
                  onAccept: (sender, session) => {
                      const { userId } = sender;
                      console.log('当远端用户同意接听',sender,session)
                      that.yinshipinType = 12;
                  },

                  /**
                   * 当有远端用户挂断 （必填）
                   * @param sender 远端用户
                   * @param reason 挂断的原因
                   * @param session 当前的 session 对象
                   */
                  onHungup: (sender, reason, session) => {
                      const { userId } = sender;
                      console.log('当有远端用户挂断',sender,reason,session)
                      that.yinshipinType = 0;
                      that.callType = false;
                      that.dialogYinShiPin = false; 

                      
                      document.getElementById("videoView").innerHTML = '';

                  },

                  /**
                   * 本端资源或远端资源已获取， （必填）
                   * @param track 本端资源或远端资源, track不可设置成 Vue 组件的响应式数据
                   * @param session 当前的 session 对象
                   */
                  onTrackReady: (track, session) => {

                      // track.isLocalTrack() 是否为本地资源
                      // track.isAudioTrack() 是否为音频
                      // track.isVideoTrack() 是否为视频
                      // track.getUserId()    产生该 track 的用户id

                      console.log('本端资源或远端资源已获取',track,session)
                        // 播放音频。如果为远端音频，建议直接播放。如为本端音频，建议不播放，以减少回音。
                      if (track.isAudioTrack() && !track.isLocalTrack()) {
                          track.play();
                      }

                      if (track.isVideoTrack()) {
                        
                        console.log('视频播放2')
                          // const video = document.getElementById("videoView") as HTMLVideoElement;
                          let container = document.getElementById("videoView");
                          let uid = track.getUserId();
                          let node = document.createElement('div');
                          node.setAttribute('id', `video-${uid}`);
                          let videoTpl = `<video id="${uid}"></video>`;
                          node.innerHTML = videoTpl;
                          node.classList = 'video-item';
                          console.log(container)
                          container.appendChild(node);
                          let videoEl = document.getElementById(track.getUserId());

                        console.log(videoEl)
                        track.play(videoEl)


                          // track.play(video);
                      }

                  },
                   /**
                       * 通话类型改变时触发，例如： 从视频变成音频通话 （必填）
                       * @param sender 发送者
                       * @param mediaType 1为音频通话   2为视频通话
                       * @param session 当前的 session 对象
                       */
                      onMediaModify: (sender, mediaType, session) => {
                        
                        console.log('当有远端用户挂断',sender,mediaType,session)
                      },
                      /**
                       * 对方静音后触发 （必填）
                       * @param muteUser 已静音的用户
                       * @param session  当前的 session 对象
                       */
                      onAudioMuteChange: (muteUser, session) => {
                        
                        console.log('对方静音后触发',muteUser,session)
                      },

                      /**
                       * 对方禁用视频后触发 （必填）
                       * @param muteUser 已禁用视频的用户
                       * @param session  当前的 session 对象
                       */
                      onVideoMuteChange: (muteUser, session) => {
                        
                        console.log('对方禁用视频后触发',muteUser,session)
                      },
                 
               
                 

                 
              }
            })

          if (code === RCCallErrorCode.SUCCESS) {
              // do something

              console.log('通话成功')
          }
        }
       
       
      },
      addKfNotice(data){
        addKfNoticeCount(data).then(response => {
          console.log('添加客服--------------',response)
      
        })
      },
      
      // 接听
      accept(){
        let that = this;
          that.sessionObj.accept().then(({ code }) => {
            if (code === RCCallErrorCode.SUCCESS) {
              console.log('接听成功')
              that.yinshipinType = 22;
            } else {
              console.log(`接听失败，错误原因：${code}`)
            }
        })
      },
      // 挂断
      hungup () {
        let that = this;
        that.sessionObj.hungup().then(({ code }) => {
          if (code === RCCallErrorCode.SUCCESS) {
            // that.dialogYinShiPin = false
            console.log('挂断成功')
            that.yinshipinType = 0;
            that.callType = false;
            that.dialogYinShiPin = false;
          } else {
            console.log(`接听失败，错误原因：${code}`)
          }
        })
      },

      callGet(session){
            let that = this;
            if(that.callType){
            console.log('通话占用，进行挂断')
            session.hungup()
            return
          }
          that.sessionObj = session;
          that.yinshipinType = 21;
          that.callType = true;
          this.dialogYinShiPin = true;
         
          console.log(`收到的通话邀请弹框成功`,this.dialogYinShiPin);

          
        },
      registerCallSessionEvent(session){
        let that = this;
        session.registerSessionListener({
          onRinging: (sender) => {
            RCToast(`收到 ${sender.userId} 振铃`);
          },
          /**
           * 当远端用户已开始响铃，表示对方已收到呼叫请求
           * @param sender 已响铃的用户
           * @param session 当前的 session 对象
           */
           onRinging: (sender) => {
              console.log('开始振铃',sender)
              const { userId } = sender;
          },


          /**
           * 当远端用户同意接听
           * @param sender 远端用户
           * @param session 当前的 session 对象
           */
           onAccept: (sender) => {
              console.log(`${sender.userId} 已接听`);
              console.log('当远端用户同意接听',sender)
              that.yinshipinType = 22;
            },
        
          /**
           * 当有远端用户挂断
           * @param sender 远端用户
           * @param reason 挂断的原因
           * @param session 当前的 session 对象
           */
         
           onHungup: (sender) => {
            console.log(`${sender.userId} 已挂断`);
              console.log('当有远端用户挂断',sender)
              that.yinshipinType = 0;
              that.callType = false;
              
              document.getElementById("videoView").innerHTML = '';
          },

          /**
           * 本端资源或远端资源已获取
           * @param track 本端资源或远端资源, track 不可设置成 Vue 组件的响应式数据
           * @param session 当前的 session 对象
           */
           onTrackReady: (track) => {
              // track.isLocalTrack() 是否为本地资源
              // track.isAudioTrack() 是否为音频
              // track.isVideoTrack() 是否为视频
              // track.getUserId()    产生该 track 的用户id

              // 播放音频。如果为远端音频，建议直接播放。如为本端音频，建议不播放，以减少回音。
            if (track.isAudioTrack() && !track.isLocalTrack()) {
                  track.play();
              }

              // 视频在对应的容器里播放
              if (track.isVideoTrack()) {
                  let container = document.getElementById("videoView");
                  let uid = track.getUserId();
                  let node = document.createElement('div');
                  node.setAttribute('id', `video-${uid}`);
                  let videoTpl = `<video id="${uid}"></video>`;
                  node.innerHTML = videoTpl;
                  node.classList = 'video-item';
                  console.log(container)
                  container.appendChild(node);
                  let videoEl = document.getElementById(track.getUserId());

                  console.log(videoEl)
                  track.play(videoEl)
                  // track.play(video);
              }
          },
          onMemberModify: (sender) => {},
          onMediaModify: (sender) => {},
          onAudioMuteChange: (muteUser) => {},
          onVideoMuteChange: (muteUser) => {}


        });
      },


      onChangeUser(e){
        console.log('改变聊天对象',e)

        this.selectId = e.targetId
        
        this.selectUser =  e
        
        this.clearReadMessage()
      }
    
  }
}



</script>
<style lang="less" scoped>
.talk-box{
  width: 100%;
  height: 650px;
  /* border: 1px solid #eee; */
}
.talk-box-item{
  height: 100%;
}
/deep/ .ant-layout-footer{
  padding: 0;
}


.yinshipin-dialog /deep/  .ant-modal-content{
  width: 375px !important;
  margin: 0 auto;
}
.yinshipin-dialog .el-dialog__header{
  padding: 0;
}
.yinshipin-dialog /deep/  .ant-modal-body{
  padding: 0;
}

.yinshipin-container{
  background-color: #000;
  position: relative;
  width: 375px !important;
  height:  667px;
}
.yinshipin-video-box{
  background-color: rgb(197, 126, 126);
  width: 100%;
  height: 100%;
}
.yinshipin-box  {
  width: 375px !important;
  height:  667px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}
.yinshipin-head{
  height: 50px;
  line-height: 50px;
  color: #fff;
}
.yinshipin-content{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.yinshipin-content-head{
  width: 108px;
  height: 108px;
}
.yinshipin-content-name{
 
  line-height: 67px;
  font-weight: bold;
  font-size: 24px;
  color: #FFFFFF;
}
.yinshipin-bottom{
  height: 110px;
  display: flex;
  justify-content: space-around;
}
.yinshipin-bottom-item{
  width: 60px;
  color: #fff;
}
.yinshipin-bottom-img{
  width: 60px;
  height: 60px;
}
</style>