
<template>
    <div class="message-deitor-box">
        <div class="message-deitor-head">
          <!-- 图片  小视频 常用语  语音通话  视频通话 -->
        

          <a-upload
            accept=".jpg,.png"
            action=""
            :show-upload-list="false"
            :object-bind="1"
             @change="changeFile"
             :before-upload="beforeUpload"
            class="avatar-uploader"
            :show-file-list="false">
            <a-button class="message-deitor-head-icon"  type="link"  icon="picture">
            </a-button>
            <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
          </a-upload>
          <a-upload
            accept=".mp4,.avi"
            action=""
             :show-upload-list="false"
            :object-bind="1"
            :before-upload="beforeUpload"
            @change="changeFileVideo"
            class="avatar-uploader"
            :show-file-list="false">
            <a-button class="message-deitor-head-icon"  type="link"  icon="play-square">
            </a-button>
            <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
        </a-upload>
          <!-- <a-button class="message-deitor-head-icon" icon="el-icon-phone-outline" @click="onYuYin"  type="text"></a-button> -->
          <!-- <a-button class="message-deitor-head-icon" icon="el-icon-video-camera" @click="onShiPin"  type="text"></a-button> -->

          <!-- <a-button class="message-deitor-head-icon"  type="link"  @click="onYuYin" icon="phone"></a-button>
          <a-button class="message-deitor-head-icon"  type="link"  @click="onShiPin" icon="video-camera"></a-button> -->
        </div>

        <a-textarea v-model:value="textarea" showCount :maxlength="200" />
        
          <div class="message-deitor-bottom">
            <a-button type="primary" @click="sendMsg('text')">发送</a-button>
          </div>

          <video class="video-img-box" :src="videoUrl"></video>
    </div>
  </template>
  
  <script>
  
  import { queryAutographSign } from '@/api/shop/shop'
//  import requestFrom from '@/utils/requestFrom'
  import axios from 'axios'
  export default {
    name: 'ConversationList',
    props: {
      msg: String,
      msgArry: Array

    },
    data() {
      return {
        textarea:'',
        uploadUrl:'',
        videoUrl:'',
        videoPath:''
      };
    },
    methods: {
      sendMsg(type){
        if(type == 'text'){
          console.log('发送文字',this.textarea)
          if(!this.textarea){
            console.log('请输入内容')
            return
          }

          this.$emit("sendMsg", {type:'text',message:this.textarea});
          this.textarea = '';
        }
      },
      submitUpload() {
        this.$refs.upload.submit();
      },
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview(file) {
        console.log(file);
      },

      beforeUpload(file) {
         
          return false;
      },
      changeFile(file) {
        let that = this;
        console.log('上传001',file);
        // let this.data = [];
	      // this.fn = json.success;
	      // this.complete = json.complete;
	      // this.files = files;
	      // this.fileLen = this.files.length;
	      // this.curIndex = 0;
	      // uni.showLoading({
	      //     title: global.i18n['上传中']
	      // });
	      // this.upload();
        
        queryAutographSign().then((res) => {

          console.log('上传002',res);
          if(res.code == "20001"){
            let ossData = res.data;
				    console.log('上传信息',ossData)
				  	let url = ossData.staticUrl;
					  let nameImg = 'images'+new Date().getTime()+'.png'
           
            let formData = new FormData()
           
            formData.append('key', nameImg)
            formData.append('policy', ossData.policy)
            formData.append('OSSAccessKeyId', ossData.accessKeyId)
            formData.append('success_action_status', '200')
            formData.append('signature', ossData.securityToken)
             formData.append('file', file.file)

            axios({
              url: url,
              method: 'post',
              headers: { "Content-Type": "multipart/form-data" },
              data: formData
					  }).then(resdata => {
               console.log('上传结果',resdata);
               if (resdata.status == 200) {
                    
                        let reader = new FileReader()
                        reader.readAsDataURL(file.file)
                        reader.onload=function(){
                          let base64Path = reader.result
                          let uploadImg = {
                              id: ossData.key,
                              path: ossData.staticUrl + nameImg
                          }
                          console.log('上传成功',uploadImg,base64Path);

                          that.$emit("sendMsg", {
                              type:'image',
                              content: base64Path, // 图片缩略图，应为 Base64 字符串，且不可超过 80KB
                              imageUri: uploadImg.path // 图片的远程访问地址 
                            });
                        }
                         
                        
                        } else {
                            // message.info(global.i18n.t('上传失败，请重试'))
                            console.log('上传失败，请重试');
                        }
            })
          
          }
        })


      },

      async changeFileVideo(file) {
        let that = this;
        console.log(file);
        let vdieoMsg = await that.getVideoMsg(file.file)

        console.log('我的视频信息',vdieoMsg);
      //  return
        // let this.data = [];
	      // this.fn = json.success;
	      // this.complete = json.complete;
	      // this.files = files;
	      // this.fileLen = this.files.length;
	      // this.curIndex = 0;
	      // uni.showLoading({
	      //     title: global.i18n['上传中']
	      // });
	      // this.upload();
       
        queryAutographSign().then((res) => {

          console.log(res);

          if(res.code == "20001"){
            let ossData = res.data;
				    console.log('上传信息',ossData)
				  	let url = ossData.staticUrl;
					  let nameImg = 'video'+new Date().getTime()+ file.file.name
           
            let formData = new FormData()
           
            formData.append('key', nameImg)
            formData.append('policy', ossData.policy)
            formData.append('OSSAccessKeyId', ossData.accessKeyId)
            formData.append('success_action_status', '200')
            formData.append('signature', ossData.securityToken)
             formData.append('file', file.file)

            axios({
              url: url,
              method: 'post',
              headers: { "Content-Type": "multipart/form-data" },
              data: formData
					  }).then(resdata => {
               console.log('上传结果',resdata);

              //  return
               if (resdata.status == 200) {
                    
                        // let reader = new FileReader()
                        // reader.readAsDataURL(file.file)
                        // reader.onload=function(){
                        //   let base64Path = reader.result
                       
                          let uploadVideo = {
                              content: vdieoMsg.imgpath,
                              sightUrl: ossData.staticUrl + nameImg,
                              duration: vdieoMsg.time,
                              size: file.file.size
                          }
                          console.log('上传成功视频',uploadVideo);
                          // console.log('上传成功',uploadImg,base64Path);

                          that.$emit("sendMsg", {
                              type:'video',
                              content: uploadVideo
                            });
                        // }
                         
                        
                        } else {
                            // message.info(global.i18n.t('上传失败，请重试'))
                            console.log('上传失败，请重试');
                        }
            })
           
          }
        })


      },

      //获取video信息
      getVideoMsg(file){
        return new Promise((resolve, reject) => {
          const video = document.querySelector('video');
          video.preload = 'metadata';
 
          // 设置文件为 Video 源
          video.src = URL.createObjectURL(file);
          let duration = 0
            video.onloadedmetadata = () => {
              // 获取视频时长
              duration = video.duration
              
            };
            video.oncanplay = () => {
                const canvas = document.createElement('canvas');
                setTimeout(()=>{
                canvas.width = video.videoWidth/10;
                canvas.height = video.videoHeight/10;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
               
                  let imgpath = canvas.toDataURL('image/png');
                  console.log(imgpath)
                  // URL.revokeObjectURL(objectURL);  // 释放URL对象
                  this.videoPath = imgpath
                  let data = {
                    time: duration,
                    imgpath: imgpath
                  }
                  resolve(data);
                },1000)
            
            };
            video.onerror = () => {
              // 处理错误
              reject(new Error('视频文件无法加载'));
            };
          });
      },
      fileSuccessST(res, file, fileList) {
        console.log(fileList,queryAutographSign)
          
          // queryAutographSign
        // this.banner_imgs = fileList
      },

      //
      onYuYin(){
        this.$emit("sendMsg", {type:'yuyin'});
      },
      onShiPin(){
        this.$emit("sendMsg", {type:'shipin'});
      }
    }
    
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .message-deitor-box{
    /* height: 100%; */
    overflow: hidden;
    padding:0 20px;
    border-top: 1px solid #eee;
    position: relative;
    background-color: #fff;
  }
  .message-deitor-head{
    display: flex;
    padding: 10px 0;
  }
  .message-deitor-bottom{
    display: flex;
    justify-content: end;
  }
  .conversation-list-item{
    display: flex;
    padding: 16px;
    background-color: #e5e4e4;
    border-bottom: 1px solid #eee;
  }
  .active{
    background-color: #ff953e;
  }
  .conversation-list-head{
    width: 50px;
    height: 50px;
  } 
  .conversation-list-msg{
    flex: 1;
    text-align: left;
    margin-left: 16px;
  }
  .conversation-list-msg-title{
    font-weight: bold;
    margin-bottom: 6px;
  }
  .message-deitor-head-icon{
    font-size: 20px;
    margin-right  : 10px;
  }
  .video-img-box{
   max-width: 500px;
   max-height: 500px;
    position: absolute;
    z-index: -2;
     /* opacity: 0; */
    top: 0; 
  }

  </style>
  